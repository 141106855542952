import MatchDetailTable from "Common/Tables/MatchDetailTable";
import ShowFancyTable from "Common/Tables/ShowFancyTable";
import { FC, useEffect, useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import { Data1Item } from "./DetailsLeft";
import snackbarUtil from "Utils/snackBarUtil";
import { settingServices } from "Utils/setting/services";

interface Props {
  keys: string;
  mrkrtId?: any;
  rightv: boolean;
  useridType: any;
  matchedId?: string | undefined;
}

const DetailsRight: FC<Props> = ({
  keys,
  mrkrtId,
  rightv,
  useridType,
  matchedId,
}) => {
  const [betsDetails, setBetDetails] = useState<BetsData>();
  const [betsDetailsFancy, setBetDetailsFancy] = useState<BetsData>();
  const [betsDetailsOdds, setBetDetailsOdds] = useState<BetsData>();
  const [betsDeleted, setBetDeleted] = useState<BetsData>();
  const [currentPage, setCurrentPage] = useState(1);
  const [fancyLiveData, setFancyLiveData] = useState<Data1Item[] | undefined>(
    undefined
  );
  const [formValuesFilter, setFormValuesFilter] = useState<{
    [key: string]: string;
  }>({});
  const [isFancy, setIsFancy] = useState(4);
  const [userNameData, setUserNameData] = useState<any[]>([]);
  const [user_name, setUser_name] = useState("");
  const [domainList, setDomainList] = useState<DomainListPayload[]>([]);
  const [domainId, setDomainId] = useState<string>("");

  const [paylodData, setPayloadData] = useState({
    bet_id: "",
    user_id: "",
    is_void: true,
    is_fancy: 0,
    password: "",
  });

  const [limit, setLimit] = useState<number>(50);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [betcount, setBetCount] = useState<number>(0);

  const { id, sportid } = useParams();

  const getBetsData = async (
    pageSize: number,
    currentPage: number,
    is_fancy?: number,
    delete_status?: boolean,
    isBeep?: boolean
  ) => {
    const { domain_name, market_name, is_back, odds, stack, bet_id } =
      formValuesFilter;
    const searchQuery: any = {
      bet_result_id: { $eq: null },
      ...(is_fancy !== 4 && { is_fancy }),
      ...(user_name && { user_name }),
      ...(domainId &&
        domainId.length > 3 && {
          domain_name: domainId?.trim()?.toLowerCase(),
        }),
      ...((delete_status || isDeleted) && { delete_status: 1 }),
      ...(market_name && { selection_name: market_name }),
      ...(is_back && { is_back }),
      ...(bet_id && { ip_address: bet_id }),
      ...(odds && { odds }),
      ...(stack && { stack }),
      ...(mrkrtId && !rightv && { [keys]: mrkrtId }),
    };
    const { response } = await authServices.getBets({
      match_id: id || matchedId || "",
      limit: limit,
      page: currentPage,
      search: searchQuery,
    });
    const betData = (response && response?.data) || [];
    if (betData && betData.metadata && betData.metadata.length > 0) {
      setListTotal({
        total: betData.metadata[0]?.total
          ? betData.metadata[0].total / limit
          : 0,
        page: betData.metadata[0]?.page || 0,
      });
    } else {
      setListTotal({
        total: 0,
        page: 0,
      });
    }

    if (is_fancy === 0) {
      setBetDetailsOdds(betData);
    } else if (is_fancy === 1) {
      setBetDetailsFancy(betData);
    } else if (delete_status || isDeleted) {
      setBetDeleted(betData);
    } else {
      setBetDetails(betData);
    }

    if (betData && betData.metadata && betData.metadata.length > 0) {
      if (betData?.metadata[0]?.total !== betcount) {
        if (isBeep && betData?.metadata[0]?.total > betcount) {
          beepOnce();
        }
        setBetCount(betData?.metadata[0]?.total);
      }
    }
  };
  const getFancyLiveData = async () => {
    const { response } = await authServices.getOnlineFancy({
      match_id: id || "",
    });
    setFancyLiveData(response?.data);
  };

  const beepOnce = () => {
    const audio = new Audio("/beep/beep.mp3");
    audio.play();
  };

  const getUserbyUserName = async (user_name: string) => {
    const { response } = await settingServices.getUserbyUserName({
      user_name: user_name?.trim()?.toLowerCase(),
    });
    const options = response?.data.map((user: any) => ({
      value: user?.user_name,
      label: user?.user_name,
    }));
    setUserNameData(options);
  };

  const handleSelectUser = (selectedOption: any) => {
    if (selectedOption) {
      setUser_name(selectedOption.value);
    }
  };

  const handleUser = (inputValue: string) => {
    if (inputValue?.length > 3) {
      getUserbyUserName(inputValue);
    }
  };

  useEffect(() => {
    getBetsData(limit, currentPage, isFancy);
    const intervalId = setInterval(() => {
      getBetsData(limit, currentPage, isFancy, isDeleted, true);
    }, 500);

    return () => clearInterval(intervalId);
  }, [
    id,
    currentPage,
    limit,
    formValuesFilter,
    isFancy,
    user_name,
    domainId,
    isDeleted,
    betcount,
  ]);

  const getAllWebsite = async () => {
    const { response } = await authServices.getAllWebsite();
    const options = response?.data.map((user: any) => ({
      value: user?.domain_name,
      label: user.host_name,
    }));
    setDomainList(options);
  };

  useEffect(() => {
    getAllWebsite();
  }, []);

  const handleDomain = (selectedOption: any) => {
    setCurrentPage(1);
    const selectedValue = selectedOption ? selectedOption.value : "";
    setDomainId(selectedValue);
  };

  const handleAll = () => {
    setCurrentPage(1);
    setIsFancy(4);
    setIsDeleted(false);
    getBetsData(limit, 1, isFancy);
  };

  const handleMatchedBet = (is_fancy: number) => {
    setCurrentPage(1);
    setIsFancy(is_fancy);
    setIsDeleted(false);
    getBetsData(limit, 1, is_fancy);
  };
  const handleDeleteBet = (delete_status: boolean) => {
    setCurrentPage(1);
    setIsFancy(4);
    setIsDeleted(true);
    getBetsData(limit, 1, isFancy, delete_status);
  };

  const handlePageFilter = (page: number, limit: number) => {
    setCurrentPage(page);
    setLimit(limit);
  };

  const showFancy = () => {
    setIsDeleted(false);
    getFancyLiveData();
  };

  return (
    <Card
      className="p-3 overflow-auto position-sticky end-0"
      style={{ maxHeight: "calc(100vh - 105px)", top: "90px" }}
    >
      <Tab.Container defaultActiveKey="all">
        {rightv && (
          <Nav
            as="ul"
            variant="pills"
            className="arrow-navtabs nav-primary bg-light"
          >
            <Nav.Item as="li" className="mx-2">
              <Nav.Link className="px-2" eventKey="all" onClick={handleAll}>
                All Bet{" "}
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary shadow-lg">
                  {(betsDetails?.metadata && betsDetails?.metadata[0]?.total) ||
                    0}
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Nav.Link
                className="px-2"
                eventKey="match"
                onClick={() => handleMatchedBet(0)}
              >
                Match Bet{" "}
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary shadow-lg">
                  {(betsDetailsOdds?.metadata &&
                    betsDetailsOdds?.metadata[0]?.total) ||
                    0}
                </span>
              </Nav.Link>
            </Nav.Item>
            {sportid == "4" && (
              <>
                <Nav.Item as="li" className="mx-2">
                  <Nav.Link
                    className="px-2"
                    eventKey="fancy"
                    onClick={() => handleMatchedBet(1)}
                  >
                    Fancy Bet{" "}
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary shadow-lg">
                      {(betsDetailsFancy?.metadata &&
                        betsDetailsFancy?.metadata[0]?.total) ||
                        0}
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            {useridType === 0 && (
              <Nav.Item as="li" className="mx-2">
                <Nav.Link
                  className="px-2"
                  eventKey="delete"
                  onClick={() => handleDeleteBet(true)}
                >
                  Deleted Bet{" "}
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary shadow-lg">
                    {(betsDeleted?.metadata &&
                      betsDeleted?.metadata[0]?.total) ||
                      0}
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}
            {sportid == "4" && (useridType === 0 || useridType === 8) && (
              <>
                <Nav.Item as="li" className="mx-2">
                  <Nav.Link
                    className="px-2"
                    eventKey="show"
                    onClick={showFancy}
                  >
                    Show Fancy{" "}
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary shadow-lg">
                      {fancyLiveData?.length}
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
          </Nav>
        )}
        <Tab.Content className="text-muted">
          <Tab.Pane eventKey="all">
            <MatchDetailTable
              betsDetails={betsDetails}
              getBetsData={getBetsData}
              setPayloadData={setPayloadData}
              paylodData={paylodData}
              currentPage={currentPage}
              handleUser={handleUser}
              formValuesFilter={formValuesFilter}
              setFormValuesFilter={(value: any) => {
                setCurrentPage(1);
                setFormValuesFilter(value);
              }}
              page={currentPage}
              limit={limit}
              listTotal={listTotal}
              handlePageFilter={handlePageFilter}
              userNameData={userNameData}
              userName={user_name}
              handleSelectUser={handleSelectUser}
              setUser_name={setUser_name}
              useridType={useridType}
              domainList={domainList}
              domainId={domainId}
              handleDomain={handleDomain}
              betcount={betcount}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="match">
            <MatchDetailTable
              betsDetails={betsDetailsOdds}
              getBetsData={getBetsData}
              setPayloadData={setPayloadData}
              paylodData={paylodData}
              currentPage={currentPage}
              handleUser={handleUser}
              formValuesFilter={formValuesFilter}
              setFormValuesFilter={(value: any) => {
                setCurrentPage(1);
                setFormValuesFilter(value);
              }}
              page={currentPage}
              limit={limit}
              listTotal={listTotal}
              handlePageFilter={handlePageFilter}
              userNameData={userNameData}
              userName={user_name}
              handleSelectUser={handleSelectUser}
              setUser_name={setUser_name}
              useridType={useridType}
              domainList={domainList}
              domainId={domainId}
              handleDomain={handleDomain}
              betcount={betcount}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="delete">
            <MatchDetailTable
              betsDetails={betsDeleted}
              getBetsData={getBetsData}
              setPayloadData={setPayloadData}
              paylodData={paylodData}
              currentPage={currentPage}
              handleUser={handleUser}
              userNameData={userNameData}
              userName={user_name}
              formValuesFilter={formValuesFilter}
              setFormValuesFilter={(value: any) => {
                setCurrentPage(1);
                setFormValuesFilter(value);
              }}
              page={currentPage}
              limit={limit}
              listTotal={listTotal}
              handlePageFilter={handlePageFilter}
              handleSelectUser={handleSelectUser}
              setUser_name={setUser_name}
              useridType={useridType}
              domainList={domainList}
              domainId={domainId}
              handleDomain={handleDomain}
              betcount={betcount}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="fancy">
            <MatchDetailTable
              betsDetails={betsDetailsFancy}
              getBetsData={getBetsData}
              setPayloadData={setPayloadData}
              paylodData={paylodData}
              currentPage={currentPage}
              userNameData={userNameData}
              handleUser={handleUser}
              userName={user_name}
              formValuesFilter={formValuesFilter}
              setFormValuesFilter={(value: any) => {
                setCurrentPage(1);
                setFormValuesFilter(value);
              }}
              page={currentPage}
              limit={limit}
              listTotal={listTotal}
              handlePageFilter={handlePageFilter}
              handleSelectUser={handleSelectUser}
              setUser_name={setUser_name}
              useridType={useridType}
              domainList={domainList}
              domainId={domainId}
              handleDomain={handleDomain}
              betcount={betcount}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="show">
            <ShowFancyTable
              data={fancyLiveData}
              getFancyLiveData={getFancyLiveData}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};

export default DetailsRight;

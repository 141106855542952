import BreadCrumb from "Common/BreadCrumb";
import DomainSettingTable from "Common/Tables/DomainSettingTable";
import { useEffect, useState } from "react";
import { Container, Button, Card, Form, Row, Col, Alert } from "react-bootstrap";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

const DomainSetting = () => {
    const [domianData, setDomainData] = useState<WebsiteResponse[]>([]);
    const [siteTitleChanged, setSiteTitleChanged] = useState("");
    const [siteData, setSiteData] = useState({
        domain_name: "",
        host_name: "",
        site_title: ""
    });
    const [errors, setErrors] = useState({
        domain_name: "",
        host_name: "",
        site_title: ""
    });
    const [checkError, setCheckError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getWebsiteList = async () => {
        const { response } = await settingServices.getWebisteData();
        setDomainData(response?.data);
    };

    const checkSiteData = async (site_title: string) => {
        const { response } = await settingServices.checkSiteData({ site_title });

        if (response?.msg?.includes("Site title data is already exists")) {
            setSiteTitleChanged(response?.msg);
            setErrors((prev) => ({
                ...prev,
                site_title: response?.msg
            }))
        }else{
            setErrors((prev) => ({
                ...prev,
                site_title: ""
            }))
    };
        } 

    useEffect(() => {
        getWebsiteList();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;


        if (name === "host_name" && value && !urlPattern.test(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                host_name: "Please enter valid url. (e.g., https://example.com)",
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "",
            }));
        }

        setSiteData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === "site_title") {
            checkSiteData(value);
        }
    };


    const getCheckWebsiteName = async (host_name: string) => {

        try {
            const { response } = await settingServices.getCheckWebsiteName({ host_name });
            if (response?.msg?.includes("Web site name is already exists")) {
                setErrors((prev) => ({ ...prev, host_name: response?.msg }));
            } else {
                setErrors((prev) => ({ ...prev, host_name: '' }));
            }
        } catch (error) {
            setErrors((prev) => ({ ...prev, host_name: 'Error checking website name' }));
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {

        getCheckWebsiteName(siteData?.host_name);

        setSiteData((prevData) => ({
            ...prevData,
            domain_name: siteData?.host_name.replace(/^https?:\/\//, ''),
        }));

    };


    const validateForm = () => {
        let formErrors = { domain_name: "", host_name: "", site_title: "" };
        let isValid = true;
        if (!siteData.host_name) {
            formErrors.host_name = "Host name is required.";
            isValid = false;
        }
        if (!siteData.site_title) {
            formErrors.site_title = "Site title is required.";
            isValid = false;
        }
       

        setErrors(formErrors);
        return isValid;
    };


    const createWebsite = async () => {
        if (errors.site_title || errors.host_name || checkError) {
            return;  
        }
    
        if (validateForm()) {
            setIsSubmitting(true);
            
            const { response } = await settingServices.createWebsite(siteData);
            if(response){
                if (response?.status) {
                    getWebsiteList(); 
                    snackbarUtil?.success(response?.msg);  
                    setSiteData({
                        domain_name: "",
                        host_name: "",
                        site_title: ""
                    });
                } else {
                    snackbarUtil?.error(response?.msg); 
                }
            }
           
    
            setIsSubmitting(false);
        }
    };

    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
                    <BreadCrumb title="Domain Setting" pageTitle="Dashboard" back />

                </div>
                <Card>
                    <Card.Body>
                        <Form>
                            <Row className="gy-3 align-items-end">
                                <Col lg={9}>
                                    <Row className="gap-3 gap-sm-0">
                                        <Col lg={4}>
                                            <Form.Label>Website Name</Form.Label>
                                            <Form.Control
                                                name="host_name"
                                                type="text"
                                                placeholder="Website"
                                                value={siteData.host_name}
                                                onChange={handleInputChange}
                                                onBlur={handleBlur}
                                                isInvalid={!!errors.host_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.host_name}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Label>Site Title Data</Form.Label>
                                            <Form.Control
                                                name="site_title"
                                                type="text"
                                                placeholder="Title"
                                                value={siteData.site_title}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.site_title}
                                            // onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.site_title}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Label>Domain Name</Form.Label>
                                            <Form.Control
                                                name="domain_name"
                                                type="text"
                                                placeholder="Domain"
                                                value={siteData.domain_name}
                                                onChange={handleInputChange}
                                               
                                                readOnly
                                            />
                                      
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3}>
                                    <Button
                                        className="me-2 btn-sm"
                                        style={{ height: "38px" }}
                                        onClick={createWebsite}
                                        disabled={isSubmitting}
                                    >
                                        <i className="fs-lg align-middle ri-add-fill"></i> Add
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        {checkError && <Alert variant="danger">{checkError}</Alert>}
                    </Card.Body>
                </Card>


                <Card>
                    <Card.Body>
                        <DomainSettingTable domianData={domianData} getWebsiteList={getWebsiteList} />
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default DomainSetting;

import BreadCrumb from "Common/BreadCrumb";
import { ListPagination } from "Common/ListPagination";
import ParentList from "Common/Modals/ParentList";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import {
    Container,
    Card,
    Form,
    Button,
    Table,
    Pagination,
    Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface ParentLevl {
    user_id: string;
    user_name: string;
    name: string;
    user_type_id: number;
    domain_name: string;
    parent_id: string;
    parent_user_name: string;
    accessToken: string;
    login_status: string;
    is_online: number;
    message: string;
    ip_address: string;
    browser_info: string;
    parent_level_ids: ParentLevelId[];
    expireAt: string;
    login_time: string;
    logout_time: string;
}

interface ParentLevelId {
    user_id: string;
    user_name: string;
    user_type_id: number;
}

interface Props {
    activeTab: string
    username: string | undefined
}

const LoginHostry = ({ activeTab, username }: Props) => {
    const [aciveLog, setActiveLog] = useState<ParentLevl[]>([]);
    const [limit, setLimit] = useState<number>(50);
    const [page, setPage] = useState<number>(1);
    const [userName, setUserName] = useState<string>("");
    const [userIds, setUserids] = useState<string>("");
    const [showParentList, setShowParentList] = useState<boolean>(false);

    const [listTotal, setListTotal] = useState<ProfitLossTotal>({
        total: 0,
        page: 0,
    });
    const getActiveLogs = async (filters: any = {}) => {
        const { response } = await authServices.getActiveLog({
            limit: limit,
            page,
            search: {
                user_name: username
            },
            ...filters,
        });

        setActiveLog(response?.data);
        setListTotal({
            total: response?.metadata
                ? response?.metadata?.total / (filters?.limit || limit)
                : 0,
            page,
        });
    };

    const handlePageFilter = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    useEffect(() => {
        getActiveLogs({ limit, page });
    }, [page, limit, username]);


    const handleParentUserList = (user_id:string, name:string)=>{
        setUserName(name);
        setUserids(user_id);
        setShowParentList(!showParentList)
    }
    const handleCloseParentUserList = ()=>{
        setShowParentList(false)
    }


    const columns = useMemo(
        () => [
            {
                header: "No.",
                accessorKey: "no",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    const items = cell?.row?.index + 1;
                    return (
                        <div>
                            {items}
                        </div>
                    );
                },
            },
            {
                header: "User Name",
                accessorKey: "user_name",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    const items = cell?.row?.original;
                    return (
                        <div className="cursor-pointer" onClick={()=>handleParentUserList(items?.user_id, items?.user_name)}>
                            {items?.user_name}
                        </div>
                    );
                },
            },
            {
                header: "Login Time",
                accessorKey: "login_time",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    const items = cell?.row?.original;
                    return (
                        <div>
                            {moment(items?.login_time).format(
                                "DD-MM-YYYY, hh:mm:ss A"
                            )}
                        </div>
                    );
                },
            },
            {
                header: "Status",
                accessorKey: "login_time",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    const items = cell?.row?.original;
                    return (
                        <div className={`${items.login_status?.toLowerCase() == 'login_success' ? 'text-success' : 'text-danger'}`}>
                            {items?.message}
                        </div>
                    );
                },
            },
            {
                header: "IP Address",
                accessorKey: "ip_address",
                enableColumnFilter: false,
            },
            {
                header: "Browser Info",
                accessorKey: "browser_info",
                enableColumnFilter: false,
            },
        ],
        [aciveLog]
    );


    return (
        <div >
            <Card>
                <Card.Body>
                    <TableContainer
                        columns={columns || []}
                        data={aciveLog || []}
                        isBordered={false}
                        customPageSize={50}
                        isPagination={false}
                        sorting={true}
                        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
                        theadClass="text-muted table-light"
                        SearchPlaceholder="Search Products..."
                    />
                    <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
                        <TableEntry
                            limit={limit}
                            handlelimitFilter={(value: number) => handlePageFilter(1, value)}
                        />
                        <ListPagination
                            listTotal={listTotal}
                            handlePageFilter={(value: number) => handlePageFilter(value, limit)}
                        />
                    </nav>

                </Card.Body>
            </Card>
            <ParentList userName={userName} userIds={userIds} show={showParentList} clickHandler={handleCloseParentUserList} />
        </div>
    );
};

export default LoginHostry;

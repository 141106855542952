import { Dispatch, SetStateAction, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface EditCreditReferenceProps {
  show: any;
  clickHandler: any;
  creditRef: number;
  setCreditRef: Dispatch<SetStateAction<number>>;
  userName: string;
  userDetails: any;
  userIds: string;
  getUserList: any;
  setCreaditData: any;
  setUserId: Dispatch<SetStateAction<string>>;
  updateUserCr: (userId: string, newBalance: number) => void;
}

const EditCreditReference = ({
  getUserList,
  userIds,
  show,
  clickHandler,
  creditRef,
  setCreditRef,
  userName,
  userDetails,
  setCreaditData,
  setUserId,
  updateUserCr,
}: EditCreditReferenceProps) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [crData, setCrData] = useState(0);
  const [formData, setFormData] = useState({
    new_credit_reference: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    new_credit_reference: "",
    password: "",
  });

  const validateForm = () => {
    let isValid = true;
    const errors: any = {};
    if (
      !formData.new_credit_reference ||
      isNaN(Number(formData.new_credit_reference))
    ) {
      errors.new_credit_reference = "Please enter a valid amount";
      isValid = false;
    }
    if (!formData.password) {
      errors.password = "Password is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleInputBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    validateForm();
  };

  const creditData = async () => {
    const { response } = await authServices.getUpdateCr({
      new_credit_reference: Number(formData.new_credit_reference),
      user_id: userIds,
    });

    if (response?.status) {
      snackbarUtil.success(response?.msg);
      // setCreaditData(Number(formData?.new_credit_reference));
      updateUserCr(userIds, Number(formData?.new_credit_reference));

      clickHandler();
      setFormData({
        new_credit_reference: "",
        password: "",
      });
      setFormErrors({
        new_credit_reference: "",
        password: "",
      });
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (formData.password === userDetails.password) {
        creditData();
      } else {
        snackbarUtil.error("Password Not Match");
      }
    }
  };

  const handleClose = () => {
    clickHandler();
    setFormData({
      new_credit_reference: "",
      password: "",
    });
    setFormErrors({
      new_credit_reference: "",
      password: "",
    });
  };

  return (
    <Modal show={show} onHide={handleClose} className="zoomIn" scrollable>
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <span>Edit Credit Reference of {userName}</span>
        <Button variant="light btn-sm" onClick={handleClose}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body className="fs-md">
        {formData.new_credit_reference?.trim() ? (
          <div className="d-flex justify-content-between bg-light p-1 rounded">
          <div>
            Current: <strong>{creditRef}</strong>
          </div>
          <div>
            New Refrence: <strong>{Number(formData.new_credit_reference)}</strong>
          </div>
        </div>
        ) : (
          <div className="bg-light p-1 rounded text-center">
            Current: <strong>{creditRef}</strong>
          </div>
        )}
        <div className="form-floating mt-3">
          <Form.Control
            name="new_credit_reference"
            type="number"
            placeholder="New credit reference"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            value={formData.new_credit_reference}
            isInvalid={!!formErrors.new_credit_reference}
            onKeyDown={(e) => {
              if (
                e.key === "-" ||
                e.key === "e" ||
                e.key === "+" ||
                e.key === "E" ||
                e.key === "."
              ) {
                e.preventDefault();
              }
            }}
          />
          <Form.Label>New credit reference</Form.Label>
          {formErrors.new_credit_reference && (
            <Form.Control.Feedback type="invalid" className="d-block">
              {formErrors.new_credit_reference}
            </Form.Control.Feedback>
          )}
        </div>

        <div className="form-floating mt-3">
          <Form.Control
            name="password"
            type={passwordShow ? "text" : "password"}
            placeholder="Password"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            value={formData.password}
            isInvalid={!!formErrors.password}
            autoComplete="new-password"
            id="new-password"
          />
          <Form.Label>Password</Form.Label>
          {formErrors.password && (
            <Form.Control.Feedback type="invalid" className="d-block">
              {formErrors.password}
            </Form.Control.Feedback>
          )}
          <Button
            variant="link"
            className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
            type="button"
            id="password-addon"
            onClick={() => setPasswordShow(!passwordShow)}
          >
            <i
              className={`${
                !passwordShow ? "ri-eye-off-fill" : "ri-eye-fill"
              } align-middle`}
            ></i>
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer className="text-end border-top">
        <Button variant="subtle-primary" onClick={handleSubmit}>
          Update
        </Button>
        <Button className="btn-ghost-primary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCreditReference;

import BreadCrumb from "Common/BreadCrumb";
import MatchDetailTable from "Common/Tables/MatchDetailTable";
import { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { settingServices } from "Utils/setting/services";

const CurrentBets = () => {
  const user_Detail = localStorage.getItem("adminDetails");
  const useridType = JSON.parse(user_Detail || "")?.user_type_id;

  const [betsDetailsFancy, setBetDetailsFancy] = useState<BetsData>();
  const [currentPage, setCurrentPage] = useState(1);
  const [formValuesFilter, setFormValuesFilter] = useState<{
    [key: string]: string;
  }>({});
  const [userNameData, setUserNameData] = useState<any[]>([]);
  const [user_name, setUser_name] = useState("");
  const [domainList, setDomainList] = useState<DomainListPayload[]>([]);
  const [domainId, setDomainId] = useState<string>("");

  const [paylodData, setPayloadData] = useState({
    bet_id: "",
    user_id: "",
    is_void: true,
    is_fancy: 0,
    password: "",
  });

  const [limit, setLimit] = useState<number>(50);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [betcount, setBetCount] = useState<number>(0);

  const getBetsData = async (
    pageSize: number,
    currentPage: number,
    isBeep?: boolean
  ) => {
    const { market_name, is_back, odds, stack, bet_id } = formValuesFilter;
    const searchQuery: any = {
      bet_result_id: { $eq: null },
      is_fancy: 1,
      is_matched: 1,
      ...(user_name && { user_name }),
      ...(domainId &&
        domainId.length > 3 && {
          domain_name: domainId?.trim()?.toLowerCase(),
        }),
      ...(market_name && { selection_name: market_name }),
      ...(is_back && { is_back }),
      ...(bet_id && { ip_address: bet_id }),
      ...(odds && { odds }),
      ...(stack && { stack }),
    };
    const { response } = await authServices.getBets({
      limit: limit,
      page: currentPage,
      search: searchQuery,
    });
    const betData = (response && response?.data) || [];
    if (betData && betData.metadata && betData.metadata.length > 0) {
      setListTotal({
        total: betData.metadata[0]?.total
          ? betData.metadata[0].total / limit
          : 0,
        page: betData.metadata[0]?.page || 0,
      });
    } else {
      setListTotal({
        total: 0,
        page: 0,
      });
    }

    setBetDetailsFancy(betData);

    if (betData && betData.metadata && betData.metadata.length > 0) {
      if (betData?.metadata[0]?.total !== betcount) {
        if (isBeep && betData?.metadata[0]?.total > betcount) {
          beepOnce();
        }
        setBetCount(betData?.metadata[0]?.total);
      }
    }
  };

  const beepOnce = () => {
    const audio = new Audio("/beep/beep.mp3");
    audio.play();
  };

  const getUserbyUserName = async (user_name: string) => {
    const { response } = await settingServices.getUserbyUserName({
      user_name: user_name?.trim()?.toLowerCase(),
    });
    const options = response?.data.map((user: any) => ({
      value: user?.user_name,
      label: user?.user_name,
    }));
    setUserNameData(options);
  };

  const handleSelectUser = (selectedOption: any) => {
    if (selectedOption) {
      setUser_name(selectedOption.value);
    }
  };

  const handleUser = (inputValue: string) => {
    if (inputValue?.length > 3) {
      getUserbyUserName(inputValue);
    }
  };

  useEffect(() => {
    getBetsData(limit, currentPage);
    const intervalId = setInterval(() => {
      getBetsData(limit, currentPage, true);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [currentPage, limit, formValuesFilter, user_name, domainId, betcount]);

  const getAllWebsite = async () => {
    const { response } = await authServices.getAllWebsite();
    const options = response?.data.map((user: any) => ({
      value: user?.domain_name,
      label: user.host_name,
    }));
    setDomainList(options);
  };

  useEffect(() => {
    getAllWebsite();
  }, []);

  const handleDomain = (selectedOption: any) => {
    setCurrentPage(1);
    const selectedValue = selectedOption ? selectedOption.value : "";
    setDomainId(selectedValue);
  };

  const handlePageFilter = (page: number, limit: number) => {
    setCurrentPage(page);
    setLimit(limit);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2">
          <BreadCrumb title="Current Bets" pageTitle="Dashboard" back />
        </div>
        <Card >
            <Card.Body>
          <MatchDetailTable
            betsDetails={betsDetailsFancy}
            getBetsData={getBetsData}
            setPayloadData={setPayloadData}
            paylodData={paylodData}
            currentPage={currentPage}
            userNameData={userNameData}
            handleUser={handleUser}
            userName={user_name}
            formValuesFilter={formValuesFilter}
            setFormValuesFilter={(value: any) => {
              setCurrentPage(1);
              setFormValuesFilter(value);
            }}
            page={currentPage}
            limit={limit}
            listTotal={listTotal}
            handlePageFilter={handlePageFilter}
            handleSelectUser={handleSelectUser}
            setUser_name={setUser_name}
            useridType={useridType}
            domainList={domainList}
            domainId={domainId}
            handleDomain={handleDomain}
            betcount={betcount}
          />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default CurrentBets;

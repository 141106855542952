import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import { result, size } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { authServices } from "Utils/auth/services";

interface BetHistoryTableProps {
  betHistoryData: BetHistoryList[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
  handleUser: (selectedOption: any) => void;
  userNameData: any[];
  userName: string;
  handleSelectUser: any;
  setUser_name: any;
  marketOptions: any[];
  selectedMarketId: any;
  handleMarketFilter: (e: any) => void;
  handleClearMarket: () => void;
  odds: string;
  stack: string;
  handleOddsStake: (e: any, name: string) => void;
  betType: string;
  handleBetChange: (e: any) => void;
}

const BetHistoryTable = ({
  betHistoryData,
  page,
  limit,
  listTotal,
  handlePageFilter,
  handleUser,
  userNameData,
  userName,
  handleSelectUser,
  setUser_name,
  marketOptions,
  selectedMarketId,
  handleMarketFilter,
  handleClearMarket,
  odds,
  stack,
  handleOddsStake,
  betType,
  handleBetChange
}: BetHistoryTableProps) => {
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  const defaultTable = useMemo(() => {
    if (!betHistoryData || !Array.isArray(betHistoryData)) {
      return [];
    }
    return betHistoryData.map((items, index: number) => ({
      sNo: (page - 1) * limit + index + 1,
      date: items?.createdAt,
      domainName: items?.domain_name || "",
      username: items?.user_name,
      sports: items?.sport_name,
      series: items?.series_name,
      market: items?.market_name,
      match: items?.match_name,
      selection: items?.selection_name,
      isBack: items?.is_back,
      rate: items?.odds,
      size: items?.size,
      isFancy: items?.is_fancy,
      stake: items?.stack,
      pnl: items?.p_l,
      roundId: items?.bet_id,
      userId: items?.user_id,
      ipAddress: items?.ip_address,
      result: items?.winner_name,
    }));
  }, [betHistoryData]);

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "sNo",
        enableColumnFilter: false,
      },
      {
        header: "Domain",
        accessorKey: "sdomain",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.domainName}
            </span>
          );
        },
      },
      {
        header: "Name",
        accessorKey: "user_name",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return (
            <span
              className="cursor-pointer text-info"
              onClick={() => {
                toggleList(cell?.row?.original?.username);
                getUserAgent(cell?.row?.original?.userId);
              }}
            >
              {cell?.row?.original?.username}
            </span>
          );
        },
      },
      {
        header: "Market",
        accessorKey: "matchMarket",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">{cell?.row?.original?.market}</p>
            </div>
          );
        },
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return (
            <div>
              <p
                className={`mb-0 btn-${
                  cell?.row?.original?.isBack ? "back" : "lay"
                } d-inline px-2 rounded-pill`}
              >
                {cell?.row?.original?.isBack ? "Back" : "Lay"}
              </p>
            </div>
          );
        },
      },
      {
        header: "Selection",
        accessorKey: "selection",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                <strong>{cell?.row?.original?.selection}</strong>
              </p>
            </div>
          );
        },
      },
      {
        header: "Rate",
        accessorKey: "rate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.rate}
            </span>
          );
        },
      },
      {
        header: "Stake",
        accessorKey: "stake",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.stake.toFixed(2)}
            </span>
          );
        },
      },
      {
        header: "P|L",
        accessorKey: "profitLoss",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span
              className={`cursor-pointer text-${
                cell?.row?.original?.pnl < 0 ? "danger" : "secondary"
              }`}
            >
              {cell?.row?.original?.pnl.toFixed(2)}
            </span>
          );
        },
      },
      {
        header: "Result",
        accessorKey: "result",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.result}
            </span>
          );
        },
      },
      {
        header: "Placed Time",
        accessorKey: "placedTime",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span>
              {moment(cell?.row?.original?.date).format("DD-MM-YY")}{" "}
              {moment(cell?.row?.original?.date).format(" hh:mm:ss A")}
            </span>
          );
        },
      },
      {
        header: "BetId",
        accessorKey: "betID",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.roundId}
            </span>
          );
        },
      },
      {
        header: "IP Address",
        accessorKey: "ip",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-info">
              {cell?.row?.original?.ipAddress}
            </span>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Button
              variant="outline-warning"
              className="me-2 btn-sm "
              style={{ height: "38px" }}
            >
              <i className="fs-lg align-middle bi-check"></i>
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={defaultTable || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        isFilter={true}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
        handleUser={handleUser}
        userNameData={userNameData}
        userName={userName}
        handleSelectUser={handleSelectUser}
        setUser_name={setUser_name}
        marketOptions={marketOptions}
        selectedMarketId={selectedMarketId}
        handleMarketFilter={handleMarketFilter}
        handleClearMarket={handleClearMarket}
        odds={odds}
        stack={stack}
        handleOddsStake={handleOddsStake}
        betType={betType}
        handleBetChange={handleBetChange}
      />
      <nav
        aria-label="Page navigation"
        className="px-3 d-flex flex-column flex-sm-row align-items-center"
      >
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        {agentList?.map((item) => {
          return (
            <Modal.Body>
              {item.name}({item.user_name})
            </Modal.Body>
          );
        })}
      </Modal>
    </>
  );
};

export default BetHistoryTable;

import FraudBetTable from "Common/Tables/FraudBetTable";
import { useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { authServices } from "Utils/auth/services";

interface FraudBetProps {
  fraudBetType: number;
  activeTab: string;
}

const FraudBetComponent = ({ fraudBetType, activeTab }: FraudBetProps) => {
  const [fraudBetsData, setFraudBetsData] = useState<FraudBets[]>([]);
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });

  const getFraudBets = async (filters: any = {}) => {
    const { response } = await authServices.getFraudBets({
      limit: limit,
      page: page,
      ...filters,
    });

    if (response?.status) {
      setFraudBetsData(response?.data?.data || []);
      setListTotal({
        total: response?.data?.metadata[0]?.total
          ? response?.data?.metadata[0]?.total / (filters?.limit || limit)
          : 1,
        page: response?.data?.metadata[0]?.page || page,
      });
    }
  };

  useEffect(() => {
    if (activeTab === "FraudBets") {
      setPage(1);
      setLimit(50);

      getFraudBets({
        page: 1,
        limit: 50,
        search: {
          is_fraud_bet: fraudBetType,
        },
      });
    }
  }, [fraudBetType, activeTab]);

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    getFraudBets({
      page: page,
      limit: limit,
      search: {
        is_fraud_bet: fraudBetType,
      },
    });
  };

  return (
    <div className="mt-5">
      
            <FraudBetTable
              data={fraudBetsData}
              page={page}
              limit={limit}
              listTotal={listTotal}
              handlePageFilter={handlePageFilter}
            />
          
    </div>
  );
};

export default FraudBetComponent;
